import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const Verify = (props) => {
    const[otpval1,setotpval1]=useState("")
    const[otpval2,setotpval2]=useState("")
    const[otpval3,setotpval3]=useState("")
    const[otpval4,setotpval4]=useState("")
    const[error,seterror]=useState(false)
    const [otp,setotp]=useState("")
    const [successMsg,setSuccessMsg]=useState("")
    const [hideResendOtp,setHideResendOtp]=useState(true)
    var otpval=''

    const navigate=useNavigate()

    useEffect(()=>{
       // verifyOtpApi()
          otpvalidation()
    },[])

    let userotp=otpval1+otpval2+otpval3+otpval4

   const otpvalidation=()=>{
    if(sessionStorage.getItem("cvalue")==1){
        for(var i=0;i<props.ot.length;i++){
            setotpval1(props.ot[0])
            setotpval2(props.ot[1])
            setotpval3(props.ot[2])
            setotpval4(props.ot[3])
        }
        setHideResendOtp(false)
    }else if(sessionStorage.getItem("cvalue")==0){
        sendSMSApi();
    }
   }
    // encrypt and decrypt with crypto-js
    

    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);
    // AES Encryption function
    const encryptData = (data) => {
      try {
        const cipherText = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        return cipherText.toString();
      } catch (error) {
        console.error("Enc Err:", error);
      }
    };

    // AES Decryption function
    const decryptData = (encryptedData) => {
      try {
        const decryptedText = CryptoJS.AES.decrypt(encryptedData, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        return decryptedText.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        throw new Error(error);
      }
    };

    // Example usage for testing only
    // const dataToEncrypt = "Hello, World!";
    // const encryptedData = encryptData(dataToEncrypt);
    // console.log("Encrypted Data:", encryptedData);

    // const decryptedData = decryptData(encryptedData);
    // console.log("Decrypted Data:", decryptedData);

    const sendSMSApi=()=>{
      let otp = sessionStorage.getItem("otp");
        let data={
            msg:`Use ${otp} your login OTP SMS.SMS is confidential. GIO Voyage never calls you asking for SMS.`,
            // ph:sessionStorage.getItem("code")+sessionStorage.getItem("phone")
            // msg:`Test`,
            ph: props.code.replace(/[^\d.+]/g,'').replace("+","") + props.phone
         }
         console.log("enc:", encryptData(JSON.stringify(data)));
         let formData = new FormData();
         formData.append("req",encryptData(JSON.stringify(data)));
         fetch(
            // "https://rentals.stitchmyapp.com/ver5.1/webservices/tw_sms/index.php?",
            // "https://app.giovoyage.com/webservices/tw_sms/index.php?",
            `https://app.giovoyage.com/webservices/tw_sms/index2.php`,
            {
             method: "POST",
            //  headers: {
            //      "Accept": "application/json",
            //      "Content-Type": "application/json"
            //  },
            //  body: JSON.stringify(data)
            body: formData
         }).then((res)=>{
             res.json().then((result)=>{
                 if(res.status==200){
                    //setSuccessMsg(result.response)
                    seterror(false)
                 }else{
                  //  setSuccessMsg(result.message)
                    seterror(false)
                 }
             })
         })
     }
     const handleGenerate=()=>{
        var range='0123456789'
        for(var i=0;i<4;i++){
            otpval+=range[Math.floor(Math.random()*10)]
        }
        setotp(otpval)
        sessionStorage.setItem("otp",otpval)
       }

     const handleResend=()=>{
        handleGenerate()
        sendSMSApi();
        setSuccessMsg("OTP Send Successfully")
     }
   

    const handleVerify=()=>{
        if(userotp===sessionStorage.getItem("otp")){
           sessionStorage.setItem("key",props.result.response.api_key)
           sessionStorage.setItem("code",props.result.response.c_code)
           sessionStorage.setItem("phone",props.result.response.u_phone)
           sessionStorage.setItem("userid",props.result.response.user_id)
           sessionStorage.setItem("fname",props.result.response.u_fname)
           sessionStorage.setItem("lname",props.result.response.u_lname)
           sessionStorage.setItem("email",props.result.response.u_email)
           sessionStorage.setItem("name",props.result.response.u_name)
           sessionStorage.setItem("pass",props.result.response.u_password)
            let path='/';
            navigate(path)
            seterror(false)
            window.location.reload()
        }
        if(userotp!=sessionStorage.getItem("otp")){
            setSuccessMsg(false)
            seterror(true)
        }
    }
    return (
        <>
            <div class="verify-frm" style={{marginTop:"15%"}}>
                {error?<div className='alert alert-success'>Invalid OTP </div>:""}
                {successMsg?<div className='alert alert-success'>{successMsg}</div>:""}
            <h2>
                Verify Phone Number
            </h2>
            <p style={{marginTop:"20px",fontSize:"16px",fontWeight:"600"}}>Check your SMS messages.We've sent you the PIN at {props.code.replace(/[^\d.+]/g,'')}{props.phone}</p>
           
            <div className="otp-header">
               <div style={{display:"flex",justifyContent:"space-between",marginBottom:"20px",marginTop:"20px"}}>
                 <input style={{fontWeight:"600",fontSize:"15px"}} value={otpval1.replace(/\D/g,'')} type="text" onChange={(e)=>setotpval1(e.target.value)} maxLength={1}/>
                <input style={{fontWeight:"600",fontSize:"15px"}} value={otpval2.replace(/\D/g,'')} type="text" onChange={(e)=>setotpval2(e.target.value)} maxLength={1}/>
                <input style={{fontWeight:"600",fontSize:"15px"}} value={otpval3.replace(/\D/g,'')} type="text" onChange={(e)=>setotpval3(e.target.value)} maxLength={1}/>
                <input style={{fontWeight:"600",fontSize:"15px"}} value={otpval4.replace(/\D/g,'')} type="text" onChange={(e)=>setotpval4(e.target.value)} maxLength={1}/>
               </div>
            </div>
           
          {hideResendOtp?<p style={{fontSize:"18px",cursor:"pointer"}}> <a onClick={handleResend} class="forget-pass">Resend OTP</a></p>:null}
            <button style={{marginTop:"20px"}} type='submit' onClick={handleVerify} class="reg-btn">Verify <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></button>
            </div>
        </>
    )
}
export default Verify