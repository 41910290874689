import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import data from "../login/record";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import moment from "moment";
import "../profile/booking.css";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { toast } from "react-toastify";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import InterCity from "./InterCity";
import RentCar4 from "./RentCar";
import RentCar from "./RentCar";
import Modal from "react-modal";
// import Payment from "../payment/Payment";
import TripPayment from "../payment/Payment";
import SuccessfulPaymentPage from "../payment/SuccessfulPaymentPage";
// import PayTypeSelect from "../payment/PayTypeSelect";
// import PaypalPayment from "../payment/paypal_payment/PaypalPayment";

const Booknow = () => {
  const today = dayjs();

  const futureTime = today.add(15, "minute");

  const fTime = today.add(15, "minute");
  const [myDate, setMyDate] = useState(fTime);
  const [customGMTtime, setCustomGMTtime] = useState(
    moment(fTime.$d).format("YYYY-MM-DD" + " " + "HH:mm:ss")
  );
  const [selectTime, setSelectTime] = useState("");

  // var d = new Date(customGMTtime);
  // console.log("customGMTtime.......",customGMTtime);
  // var utc_offset = d.getTimezoneOffset();
  // d.setMinutes(d.getMinutes() + utc_offset);
  // const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm:ss A");
  // console.log("GMTtime..............",GMTtime);

  const [select, setselect] = useState();
  const [time, settime] = useState(today);
  const [city, setcity] = useState(
    data?.city_id || process.env.REACT_APP_CITY_ID
  );

  const [category, setcategory] = useState(data?.category_id || "");
  const [err, seterr] = useState(false);
  const [drop, setdrop] = useState("");
  const [fullname, setfullname] = useState(sessionStorage.getItem("name"));
  const [phone, setphone] = useState(sessionStorage.getItem("phone"));
  const [email, setemail] = useState(sessionStorage.getItem("email"));
  const [book, setbook] = useState(false);
  const [show, setshow] = useState(true);
  const [message, setmessage] = useState("");
  const [catdata, setcatdata] = useState([]);
  const [base, setbase] = useState("");
  const [farekm, setfarekm] = useState("");
  const [faremin, setfaremin] = useState("");
  const [citydrop, setcitydrop] = useState([]);
  const [cate, setcate] = useState("");
  const [droplatlng, setdroplatlng] = useState("");
  const [pickuptrip, setpickuptrip] = useState(false);
  const [droptrip, setdroptrip] = useState(false);
  const [distance, setDistance] = useState("0.01");
  const [duration, setDuration] = useState("0.00");
  const [check, setcheck] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [calculateDistance, setCalculateDistance] = useState("");
  const [calculateDirection, setCalculateDirection] = useState("");
  const [serviceTax, setServiceTax] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [dropValue, setDropValue] = useState("");
  const [filterCateByCity, setFilterCateByCity] = useState("");
  const autocompleteRef = useRef(null);
  const dropAutocompleteRef = useRef(null);
  const [bookingForm, setBookingForm] = useState("daily");

  const [rentalPackage, setRentalPackage] = useState();
  const [rentalTotalFair, setRentalTotalFair] = useState();
  const [rentalBaseFair, setRentalBaseFair] = useState();
  const [rentalTax, setRentalTax] = useState();

  const [vehicleKey, setvehicleKey] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [categoryId, setCategoryId] = useState();
  const [packName, setPackName] = useState();
  const [catDisable, setCatDisable] = useState("false");
  const [category_Id, setCategory_Id] = useState("false");

  const [servTax, setServTax] = useState(null);

  const [BaseInclude, setbaseInclude] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openPaySuccessModal, setOpenPaySuccessModal] = useState(false);

  const [payAmount, setPayAmount] = useState(null);
  const [currentGMTtime, setCurrentGMTtime] = useState(null);
  const [paymAmount, setPaymAmount] = useState(null);
  const [isPaypal, setIsPaypal] = useState(false);
  const [estData, setEstData] = useState({});




  

  const navigate = useNavigate();
  const location = useLocation();
  const defaultCateg = location.state;
  const searchParams = new URLSearchParams(location.search);
  const paymentStatus = searchParams.get("redirect_status");

   
  


  const selectedTime = time.$d
    ? moment(time.$d).seconds(0).milliseconds(0)
    : moment(time).seconds(0).milliseconds(0);
  const currentTime = moment().seconds(0).milliseconds(0);

  useEffect(() => {
    let username = sessionStorage.getItem("email");
    if (username === "" || username === null) {
      navigate("/login");
    }
  }, []);

  const [address, setaddress] = useState("");
  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  useEffect(()=>{
    const currentGMTString = new Date().toGMTString();
const formattedDate = moment(currentGMTString, 'ddd, DD MMM YYYY HH:mm:ss GMT').format("YYYY-MM-DD" + " " + "HH:mm:ss");
    setCurrentGMTtime(formattedDate)
  },[])

  useEffect(()=>{
    
    const bookingdata = sessionStorage.getItem('bookingdetails');
    
    // Parse the JSON string back to an array
    const bookingdetails = JSON.parse(bookingdata);
    if(bookingdetails){
      handleSelect(bookingdetails.addressValue)
      handleDrop(bookingdetails.dropValue)

      let vehicle = bookingdetails.selectedVehicle
      catdata?.map((item,i)=>{
        if(item.cat_name === vehicle){
          chooseVehicle(item,i)
          setvehicleKey(i);
          setSelectedVehicle(item.cat_name);
          setCategoryId(item.category_id);
          setCategory_Id(item.category_id);
          setPackName(item.name);
          setcategory(i);
        }
      })
    }

    // setTimeout(()=>{
    //   sessionStorage.removeItem('bookingdetails');
    // },9000)
    // Retrieve the JSON string from session storage

  },[catdata])


  useEffect(()=>{
    setTimeout(()=>{
      sessionStorage.removeItem('bookingdetails');
    },8000)
  },[])

  useEffect(()=>{
    if (paymentStatus == "succeeded") {
      // setcheck("")
    setOpenPaySuccessModal(true)
  }
  else{
    setOpenPaySuccessModal(false)
  }
  },[paymentStatus])

  const handleTimeChange = (newTime) => {
    const currentTime = moment();
    const selectedTime = moment(newTime);
    if (selectedTime.isAfter(currentTime)) {
      settime(newTime);
    } else {
      toast.error("Please select a future time");
    }
  };

  const handleDateAndTime = (newTime) => {
    setCustomGMTtime(
      moment(newTime.$d).format("YYYY-MM-DD" + " " + "HH:mm:ss A")
    );
    setMyDate(newTime.$d);

    const time = moment(newTime.$d).format("YYYY-MM-DD" + " " + "HH:mm:ss");
    updateGMT(time);
    setselect(newTime);
    settime(newTime);
  };

  useEffect(() => {
    if (!city) {
      setcategory("");
      setAddressValue("");
      setaddress("");
      setDropValue("");
      setdrop("");
      setcheck("")
    }
  }, [city]);

  const carouselCatData = localStorage.getItem("carouselCatData");

  useEffect(() => {
    if (carouselCatData) {
      const data = JSON.parse(carouselCatData);
      setbaseInclude(data.cat_base_includes);
      setcity(data.city_id);
      setbase(data.cat_base_price);
      setfarekm(data.cat_fare_per_km);
      setfaremin(data.cat_fare_per_min);

      setFilterCateByCity(data.city_id);
      setcategory(data.category_id);
      localStorage.removeItem("carouselCatData");
    }
  }, [carouselCatData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        autocompleteRef?.current &&
        !autocompleteRef?.current?.event?.target?.value &&
        !addressValue
      ) {
        setaddress("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [address, addressValue]);

  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropValue
      ) {
        setdrop("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [drop, dropValue]);

  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
    setdrop("");
    setDropValue("");
    setEstData({});
    setPaymAmount(null);
  };

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  const onChangedrop = (e) => {
    setdrop(e);
    setDropValue("");
  };

  const handleClearDropSelection = () => {
    // setaddress("")
    // setAddressValue("");
    setDropValue("");

    setdrop("");
    setEstData({});
    setPaymAmount(null);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    setcoord(l2);
    setDropValue(result[0].formatted_address);
    setdrop(value);

    // contactApi()

    // if (address && drop  ){
    //   setCatDisable(false)
    //   return
    //  }else{
    //   setCatDisable(true)
    //  }
  };

  function handleshow() {
    setshow(false);
  }
  function handlehide() {
    setshow(true);
    setmessage(false);
  }

  useEffect(() => {
    if (filterCateByCity) {
      handleCat();
    }
  }, [filterCateByCity]);

  const handleCat = async () => {
    let data = {
      city_id: process.env.REACT_APP_CITY_ID,
    };
    await fetch(
      `${process.env.REACT_APP_URL}/categoryapi/getcategories?api_key=${apikey}`,
      // `https://app.giovoyage.com/webservices/1.1.2/index.php/categoryapi/getcategoriesv1`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcatdata(data.response);
      }).catcj((err)=>{
        console.error("Error:",err);
      });
  };

  useEffect(() => {
    catdata?.map((res, ind) => {
      if (selectedVehicle == res.cat_name) {

        setbaseInclude(res.cat_base_includes);

        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
      }
    });
  }, [catdata, vehicleKey]);

  // useEffect(() => {
  //   citiesapi();
  // }, []);

  const citiesapi = () => {
    fetch(`${process.env.REACT_APP_URL}/cityapi/getcities?api_key=${apikey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcitydrop(data.response);
      });
  };

  useEffect(() => {
    changelatlng();
    if (coord.lat.length == "" && coord.lng.length == "") {
      setCalculateDistance(0);
      setCalculateDirection(0);
      setDistance(0);
      setDuration(0);
    }
  }, [address, drop]);

  useEffect(() => {
    contactApi();
  }, [drop, address, selectedVehicle]);

  const contactApi = () => {
    if (coord?.lat.length != 0 && coord?.lng.length != 0) {
      fetch(
        // `https://rentals.stitchmyapp.com/ver5.1/webservices/push/distanceMatrix.php?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`,
        `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          // dist = setDistance(result?.routes[0]?.legs[0]?.distance?.text);
          // dura = setDuration(result?.routes[0]?.legs[0]?.duration?.text);
          if (addressValue && dropValue) {
            setDistance(result?.routes[0]?.legs[0]?.distance?.text);
            setDuration(result?.routes[0]?.legs[0]?.duration?.text);
          }
          dist = distance;
          dura = duration;
          if (addressValue && dropValue) {
            setCalculateDistance(
              result?.routes[0]?.legs[0]?.distance?.value / 1000
            );
            setCalculateDirection(
              result?.routes[0]?.legs[0]?.duration?.value / 60
            );
          }
          Base_price = base;
          Base_fare_per_km = farekm;
          fare_per_min = faremin;
        });
    } else {
      console.log("message");
    }
  };

  let dist = distance;
  let dura = duration;
  let Base_price = base;
  let Base_fare_per_km = farekm;
  let fare_per_min = faremin;
  let sr = serviceTax;
  let ST = 0;
  let GTF = 0;

  
  let jtf = (
    parseInt(Base_price) +
    (   Base_fare_per_km * (calculateDistance - BaseInclude) +
      fare_per_min * calculateDirection)
  ).toFixed(2)




  const changelatlng = () => {
    if (addressValue != pickupAddress) {
      setcoordinate({
        lat: "",
        lng: "",
      });
    }
    if (dropValue != droplatlng) {
      setcoord({
        lat: "",
        lng: "",
      });
    }
    if (coordinate.lat.length != 0 && coordinate.lng.length != 0) {
      setpickuptrip(true);
      setdroptrip(false);
    }
    if (
      coordinate.lat.length != 0 &&
      coordinate.lng.length != 0 &&
      coord.lat.length != 0 &&
      coord.lng.length != 0
    ) {
      setdroptrip(true);
      setpickuptrip(false);
    }
  };

  const selectitem = (e) => {
    setcategory(e);
    catdata.map((res, ind) => {
      if (e == res.category_id) {
        setcate(res.cat_name);
        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
      }
    });
  };
  useEffect(() => {
    const selectCity = () => {
      let e = city;
      setcity(e);
      setFilterCateByCity(e);
      // citydrop.map((res) => {
      //   setServiceTax(res.city_tax);
      // });
      setServiceTax(process.env.REACT_APP_SERVICE_TAX);
    };
    selectCity();
  }, [city]);

  const userid = sessionStorage.getItem("userid");

  // const form = moment(select).format("YYYY-MM-DD");
  // const ftime = moment(time).format("hh:mm A");
  // const time_date = form + " " + ftime;
  const apikey = sessionStorage.getItem("key");

  // var d = new Date(time_date);
  // var utc_offset = d.getTimezoneOffset();
  // d.setMinutes(d.getMinutes() + utc_offset);
  // var GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm A");

  // console.log(GMTtime,"GMTtime");

  //   const cdate = new Date();
  //   console.log(myDate.$d);
  //   var newGMTtime ;
  // useEffect(()=>{
  //   newGMTtime = moment(myDate.$d).format("YYYY-MM-DD" + " " + "HH:mm:ss");
  //   console.log(newGMTtime,"newGMTtime------------");
  // },[])

  // useEffect(()=>{
  //   var d = new Date();
  //   console.log("customGMTtime.......",customGMTtime);
  //   var utc_offset = d.getTimezoneOffset();
  //   d.setMinutes(d.getMinutes() + utc_offset);
  //   const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm:ss A");
  //   console.log("GMTtime..............",GMTtime);
  // },[customGMTtime])

  var d = new Date(customGMTtime);
  var utc_offset = d.getTimezoneOffset();
  d.setMinutes(d.getMinutes() + utc_offset);
  var GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm:ss");

  const updateGMT = (time) => {

    let a = new Date(time);
    let utc_offset = a.getTimezoneOffset();
    a.setMinutes(a.getMinutes() + utc_offset);
    var GMTtime = moment(a).format("YYYY-MM-DD" + " " + "HH:mm:ss");
    setSelectTime(GMTtime);
  };


// let pay_Amount = (  calculateDistance > BaseInclude)?
//   (Math.ceil(
//     (
//       Number(jtf) + Number(servTax)
//     ).toFixed(2)
//   )) :  Math.ceil( (Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price)) 
  // setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2));

  // let pay_Amount =(  calculateDistance > BaseInclude)?
  //   (Math.ceil(
  //     (
  //       Number(jtf) + Number(servTax)
  //     ).toFixed(2)
  //   )) :  Math.ceil( (Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price)) 
  //   // setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2));
  

  useEffect(()=>{
    console.log(BaseInclude,"BaseInclude");
    console.log(jtf,"jtf");
    console.log(calculateDistance,"calculateDistance");
  let tempServiceTax  = calculateDistance > BaseInclude ?(((process.env.REACT_APP_SERVICE_TAX * Number(jtf))/100).toFixed(2)):(((process.env.REACT_APP_SERVICE_TAX * Number(Base_price))/100).toFixed(2)) 
console.log(tempServiceTax,"tempServiceTax");
  setPaymAmount( Math.ceil(

      (  calculateDistance > BaseInclude)?
      (
        Number(jtf) + Number(tempServiceTax)
        ).toFixed(2)
        : (Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price)
        
        )
        )
    console.log((  calculateDistance > BaseInclude)?
    (
      Number(jtf) + Number(tempServiceTax)
    ).toFixed(2)
  : (Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price));
  },[servTax,BaseInclude,jtf,vehicleKey])

  const BookingForm = () => {
    // e.preventDefault();
    if (Validation()) {
      handleBooknow();
      // handleshow();
    }
  };

  const handleBooknow = (e) => {
    // e.preventDefault();
    if (BookingValidation()) {
      let data = {
        trip_date: selectTime ? selectTime : GMTtime,
        cat_name: selectedVehicle,
        trip_from_loc: address,
        trip_to_loc: drop,
        city_id: city,
        user_id: userid,
        category_id: category_Id,
        trip_currency: "€",
        trip_base_fare: Base_price,
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_distance: calculateDistance,
        trip_dunit: "km",
        trip_type: "normal",
        trip_total_time: calculateDirection,
        trip_pay_amount: paymAmount,
        seats: "1",
        trip_status: "request",
        is_share: "0",
        is_delivery: "0",
        tax_amt: servTax,
        trip_pay_mode : 'Card',
        trip_pay_status : 'Paid',
        trip_pay_date : currentGMTtime,
        trip_comp_commision: estData?.trip_comp_commision,
        is_ride_later: 1,
        trip_searched_addr: dropValue,
        trip_search_result_addr: dropValue,
        trip_driver_commision: estData?.trip_driver_commision,
        est_data: {
          ...estData, 
          city_dist_unit: "km", 
          curr_multiplier: "1.0", 
          exc_dt: {
            city_id: city,
            currency: "€",
            exchange_rate: "1.0",
            pg_currency: "EUR",
            rider_amt: estData?.trip_pay_amount,
            u_city_id: city,
            u_currency: "€",
            u_pg_currency: "EUR"
          },
          r_curr: "EUR", 
          rider_amt: estData?.trip_pay_amount, 
          trip_currency: "€", 
          trip_distance: calculateDistance, 
          trip_total_time: calculateDirection
        },
      };
      console.log(data);
const jsonData = JSON.stringify(data);
// setPayAmount(
//   Math.ceil(
//             (
//               Number(jtf) + Number(servTax)
//             ).toFixed(2)
//           )

// )

sessionStorage.setItem('tripData', jsonData);

// setcheck("") 
// const handlelogout = () => {
  setOpenModal(true);
// };
      // navigate("/payment", {
      //   state: {
      //     amount: 
      //       (GTF = Math.ceil(
      //         (
      //           Number(jtf) + Number(servTax)
      //         ).toFixed(2)
      //       ))
      //   },
      // });

      // fetch(`${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`, {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // }).then((res) => {
      //   res.json().then((result) => {
      //     if (res.status == 200) {
      //       setmessage("Your request has been saved successfully,");
      //       setselect(new Date());
      //       settime(time);
      //       setcity("");
      //       setcategory("");
      //       setaddress("");
      //       setdrop("");
      //       seterr(false);
      //       toast.success("Your request has been saved successfully,");
      //       localStorage.setItem("tabValue", "bookinghistory");
      //       // navigate("/profile");

      //       navigate("/payment", {
      //         state: {
      //           amount: 
      //             (GTF = Math.ceil(
      //               (
      //                 Number(jtf) + Number(servTax)
      //               ).toFixed(2)
      //             ))
      //         },
      //       });
      //     } else {
      //       setmessage(result.message);
      //     }
      //   });
      // });
    }
  };

  const Validation = () => {
    let result = true;

    if (
      city.length == 0 ||
      category.length == 0 ||
      address.length == 0 ||
      check == "" ||
      drop.length == 0 ||
      address == drop ||
      
      !selectedTime.isSameOrAfter(currentTime)

      
    ) {

      result = false;
      seterr(true);
      setPayAmount(
        Math.ceil(
                  (
                    Number(jtf) + Number(servTax)
                  ).toFixed(2)
                )
      
      )
    }
    return result;
  };

  const BookingValidation = () => {
    let result = true;
    if (fullname.length == 0 || email.length == 0 || phone.length == 0) {
      result = false;
      setbook(true);
      setmessage(false);
    }
    return result;
  };

  const [errorWithoutCity, setErrorWithoutCity] = useState(false);
  const handleWithoutCityClick = () => {
    if (!city) {
      setErrorWithoutCity(true);
    }
  };

  useEffect(() => {
    if (address && drop) {
      setCatDisable("false");
      return;
    }
  }, [address, drop]);
  const chooseVehicle = (item, i) => {
    if (defaultCateg) {
      setCatDisable("false");
    } else if (address == "" || drop == "") {
      setCatDisable("true");
      return;
    } else {
      setCatDisable("false");
    }
    contactApi();

    setvehicleKey(i);
    setSelectedVehicle(item.cat_name);
    setCategoryId(item.category_id);
    setCategory_Id(item.category_id);
    setPackName(item.name);
    setcategory(i);
    {
      /* 
    catdata.map((res, ind) => {
    console.log(res.category_id,"ct..i....");

      console.log("res..all", res);

      console.log("res..", category_Id);
       if (i== res.category_id) {
    console.log(cID,"i.abc...");

        setcate(res.cat_name);
        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
        setServTax(((10 / 100) * GTF).toFixed(2));
      }  
    });
  */
    }
  };

  const resetDaily = () => {
    setcheck("");
    setAddressValue("");
    setdrop("");
    setDropValue("");
    setaddress("");
    setSelectedVehicle("");
    setvehicleKey(-1);
    setcategory("");
    setCatDisable("true");
  };
  useEffect(()=>{
    setPayAmount(
      Math.ceil(
                (
                  Number(jtf) + Number(servTax)
                ).toFixed(2)
              )
    
    )
  },[servTax])

  useEffect(() => {
    // let sTax = (
    //   (parseInt(Base_price) +
    //     Base_fare_per_km * calculateDistance +
    //     fare_per_min * calculateDirection) *
    //   (sr / 100)
    // ).toFixed(2);
    // (
    //   Number(base)+
    //   Number(jtf) +
    // )

    // {(  calculateDistance > BaseInclude)?
    //   (Math.ceil(
    //     (
    //       Number(jtf) + Number(servTax)
    //     ).toFixed(2)
    //   )) :  Math.ceil( (Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price)) 
    //   // setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2));
    // }


    let total = (Number(base) + Number(jtf)).toFixed(2);

    // setServTax(((50 / 100) * total).toFixed(2));
    setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2));
    
  }, [, category_Id, address, drop, vehicleKey, selectedVehicle, GTF, jtf, ST]);
  useEffect(() => {
    if (defaultCateg) {
      catdata.map((res, i) => {
        if (defaultCateg.cat.cat_name == res.cat_name) {
          chooseVehicle(defaultCateg.cat, i);
        }
      });
    }
  }, [defaultCateg, catdata]);

// // ---------------- braintree paypal payment --------------
//   const [clientToken, setClientToken] = useState('');
// useEffect(() => {
//   fetch(`${process.env.REACT_APP_URL}/braintreeapi/generatetoken?api_key=${apikey}`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     // body: JSON.stringify({ nonce: payload.nonce }),
//   })
//     // Fetch the client token from your server
//     // fetch(`https://app.giovoyage.com/webservices/1.1.2/index.php/braintreeapi/generatetoken?${apikey}`)
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(":Token:",data?.response?.token)
//         setClientToken(data?.response?.token) 
//         })
//       .catch((error) => console.error('Error fetching client token:', error));
//   }, []);
  
//   const [paypalLoading, setPaypalLoading] = useState(false);

//   useEffect(() => {
//   // Create a client.
//     if(clientToken && (isPaypal && check !== "" && vehicleKey !== null && address !== "" && drop !== address && drop !== "")){
//       window.braintree.client.create({
//         authorization: "sandbox_w3ctqgkn_fdrw3tvjc7fv84cv"
//       }, function (clientErr, clientInstance) {
//         // Stop if there was a problem creating the client.
//         // This could happen if there is a network error or if the authorization
//         // is invalid.
//         if (clientErr) {
//           console.error('Error creating client:', clientErr);
//           toast.error(`Error creating client:${clientErr}`);
//           return;
//         }
//         // Create a PayPal Checkout component.
//         window.braintree.paypalCheckout.create({
//           client: clientInstance
//         }, async function (paypalCheckoutErr, paypalCheckoutInstance) {        
//           paypalCheckoutInstance.loadPayPalSDK({
//             currency: 'EUR',
//             intent: 'capture'
//           },function (loadSDKErr) {
//             // The PayPal script is now loaded on the page and
//             if(loadSDKErr){
//               console.error("Load SDK Error:",loadSDKErr);
//               toast.error(loadSDKErr);
//             }
//             window.paypal.Buttons({
//               style: {
//                 layout: 'vertical',
//                 label:  'pay',
//                 height: 40
//               },
//               fundingSource: window.paypal.FUNDING.PAYPAL,
//               createOrder: function () {
//                 return paypalCheckoutInstance.createPayment({
//                   flow: 'checkout',
//                   // amount: 0.01,
//                   amount: payAmount,
//                   currency: 'EUR',
//                   intent: 'capture'
//                 });
//               },
      
//               onApprove: function (data, actions) {
//                 // console.log(".....DT",data);
//                 return paypalCheckoutInstance.tokenizePayment(data, async function (err, payload) {
//                   // Submit 'payload.nonce' to your server
//                   // console.log("........NONCE",payload.nonce);
//                   // console.log("........payload",payload,"\ndata.....",data);
//                   setPaypalLoading(true);
//                   await fetch(`${process.env.REACT_APP_URL}/braintreeapi/transactionsale?api_key=${apikey}&amount=${payAmount}&payment_method_nonce=${payload.nonce}&order_id=${data.orderID}`, {
//                     method: 'POST',
//                     headers: {
//                       'Content-Type': 'application/json',
//                       },
//                     }).then(res=>{
//                       setPaypalLoading(false);
//                       console.log(">>>>>>>>>>>>",res);
//                       if(res?.statusText === "OK"){
//                         window.location.href = `${process.env.REACT_APP_BASE_URL}/booknow?rider_amt=${payAmount}&redirect_status=succeeded&resData=${res}`
//                       }
//                     }).catch(err=>{
//                       setPaypalLoading(false);
//                       console.error("Braintree Transactionsale Error",err);
//                     });                  
//                 });
//               },
      
//               onCancel: function (data) {
//                 console.log('PayPal payment canceled', JSON.stringify(data, 0, 2));
//                 toast.error("PayPal payment canceled");
//               },
      
//               onError: function (err) {
//                 console.error('PayPal error', err);
//                 // toast.error(`PayPal error:${err}`);
//               }
//             }).render("#paypal-button")

//           });

//           if (paypalCheckoutErr) {
//             console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
//             toast.error(`Error creating PayPal Checkout:${paypalCheckoutErr}`);
//             return;
//           }
//         });
//       });
//     }
//     if(isPaypal){
//       BookingForm();
//     }
//   }, [clientToken, isPaypal, check, address, drop]);

  // get estimate data
  const getEstData = async (params) => {
    await fetch(`${process.env.REACT_APP_URL}/tripapi/estimatetripfare?api_key=${apikey}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((res)=>{
      return res.json();
    }).then((res)=>{
      // console.log("------------>Est Result",res.status,categoryId);
      if(res.status === "OK"){
        // console.log("------------>Est Result",res.response[categoryId]);
        setEstData(res.response[categoryId]);
        setPaymAmount(res.response[categoryId]?.trip_pay_amount);
      }
    }).catch((err)=>{
      console.error("EST_DATA_ERR",err);
    });
  }
  useEffect(() => {
    if(addressValue !== "" && dropValue !== "" && categoryId !== null){
      const params = {
        trip_to_loc: addressValue,
        is_share: 0,
        trip_from_loc: dropValue,
        seats: 1,
        trip_distance: calculateDistance,
        user_id: userid,
        trip_date: selectTime ? selectTime : GMTtime,
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_hrs: calculateDirection,
        city_id: process.env.REACT_APP_CITY_ID,
      }
      getEstData(params);
    }
  }, [calculateDistance, calculateDirection, categoryId]);
  

  return (
    <>
      <Header />
      <div className="tj-inner-banner">
        <div className="container">
          <h2>Booking Form</h2>
        </div>
      </div>

      <div className="tj-breadcrumb">
        <div className="container">
          <ul className="breadcrumb-list">
            <li>
              <a href="https://giovoyage.com/">Home</a>
            </li>
            <li className="active">Booking Form</li>
          </ul>
        </div>
      </div>

      {/* <!--Booking Form Section Start-->	 */}

      {show ? (
        <div>
          <section className="tj-booking-frm">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  <div className="tj-tabs">
                    <ul className="nav nav-tabs " role="tablist">
                      <li className="active">
                        <a
                          href="#point"
                          data-toggle="tab"
                          onClick={() => {
                            resetDaily();
                            setBookingForm("daily");
                            setMyDate(fTime);
                            setRentalPackage(null);
                            setRentalTotalFair(null);
                            setRentalBaseFair(null);
                            setRentalTax(null);
                          }}
                        >
                          Transfer
                        </a>
                      </li>
                      <li className="">
                        <a
                          href=""
                          data-toggle="tab"
                          onClick={() => {
                            setBookingForm("rentCar");
                            setRentalPackage(null);
                            setRentalTotalFair(null);
                            setRentalBaseFair(null);
                            setRentalTax(null);
                          }}
                        >
                          By hour
                        </a>
                      </li>
                      {/* <li className="">
                        <a
                          href="#point"
                          data-toggle="tab"
                          onClick={() => setBookingForm("interCity")}
                        >
                          Inter-city
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <Modal
                  
                  isOpen={openPaySuccessModal}
                  onRequestClose={() => setOpenPaySuccessModal(false)}
                  shouldCloseOnOverlayClick={false}
                  style={{
                    overlay: {
                      zIndex: 9,
                      backgroundColor:"#00000063",
                      position: "fixed",
                    },
                    content: {
                      width: "40%",
                      height: 250,
                      top: "50%",
                      left: "50%",
                      right: "auto",
                      bottom: "auto",
                      marginRight: "-50%",
                      transform: "translate(-50%,-50%)",
                    },
      
                    
                  }}
                >
                    <button className="close" onClick={() => setOpenPaySuccessModal(false)}>
                    <span>X</span>
                  </button>
                <SuccessfulPaymentPage setOpenPaySuccessModal={setOpenPaySuccessModal}/>
                </Modal>

                  <Modal
                  
                isOpen={!isPaypal ? openModal : false}
                onRequestClose={() => setOpenModal(false)}
                shouldCloseOnOverlayClick={false}
                style={{
                  overlay: {
                    zIndex: 9,
                    backgroundColor:"#00000063",
                    position: "fixed",
                  },
                  content: {
                    width: "40%",
                    // height: 420,
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%,-50%)",
                  },
    
                  
                }}
              >
                  <button className="close" onClick={() => setOpenModal(false)}>
                  <span>X</span>
                </button>
       { !isPaypal? <TripPayment payAmount={paymAmount}/>: null
       
      //  <>
      //  <div id="paypal-button">123</div>
      //  </>
      //  <PaypalPayment payAmount={paymAmount*100}/>
       }
              </Modal>
                  <div className="tab-content">
                    <div className="tab-pane active" id="point">
                      {bookingForm == "daily" ? (
                        <form
                          onSubmit={(e)=>{e.preventDefault(); BookingForm();}}
                          className="booking-frm"
                          id="User-Form"
                        >
                          <div className="col-md-6 col-sm-6">
                            <div className="field-holder">
                              {/* <MuiPickersUtilsProvider utils={DateMomentUtils}>
                              <span class="fas fa-calendar-alt"></span>
                              <DatePicker
                                disablePast
                                format="DD/MM/yyyy"
                                inputVariant="outlined"
                                value={select}
                                onChange={setselect}
                              />
                            </MuiPickersUtilsProvider> */}
                              {/* {err && form.length <= 0 ? <label id="u_email-error" className="error" style={{ color: "red" }}>This field is required</label> : null} */}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            {/* <div className="field-holder">
                            <MuiPickersUtilsProvider utils={DateMomentUtils}>
                              <span className="fas fa-clock"></span>
                              <TimePicker
                                disablePast
                                inputVariant="outlined"
                                value={time}
                                onChange={handleTimeChange}
                              />
                            </MuiPickersUtilsProvider>
                          </div> */}
                            {/* {err && time.length <= 0 ? <label id="u_email-error" className="error" style={{ color: "red" }}>This field is required</label> : null} */}
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <strong style={{ marginBottom: "4px" }}>
                              Date
                            </strong>
                            <div className="field-holder">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  value={myDate}
                                  // value={myDate}

                                  disablePast
                                  ampm={false}
                                  onChange={(e) => handleDateAndTime(e)}
                                  slotProps={{
                                    textField: {
                                      error: false,
                                      inputProps: { readOnly: false },
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm"
                                />
                              </LocalizationProvider>
                            </div>
                            {err && !selectedTime.isSameOrAfter(currentTime) ? (
                              <label
                                id="u_email-error"
                                className="error"
                                style={{
                                  color: "#e74c3c",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  marginTop: "-10px",
                                  marginBottom: "8px",
                                }}
                              >
                                Please select a future time
                              </label>
                            ) : null}
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <strong
                              style={{
                                marginTop: "10px",
                                marginBottom: "-10px",
                              }}
                            >
                              From
                            </strong>
                            <div className="field-holder border padding-input">
                              <PlacesAutocomplete
                                value={address}
                                onChange={onChangeAddress}
                                onSelect={handleSelect}
                                ref={autocompleteRef}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <span className="fas fa-map-marker-alt"></span>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Pickup Location",
                                        className: "location-search-input",
                                      })}
                                      readOnly={addressValue}
                                    />
                                    {addressValue && (
                                      <span
                                        className="fas fa-times-circle"
                                        style={{
                                          marginRight: "20px",
                                          fontSize: "17px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleClearSelection}
                                      ></span>
                                    )}

                                    <div className="autocomplete-dropdown-container">
                                      {/* {loading && <div>Loading...</div>} */}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            className="input-suggestion"
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                // className,
                                                style,
                                              }
                                            )}
                                          >
                                            <i class="material-icons ">
                                              location_on
                                            </i>{" "}
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                              {err && address.length <= 0 ? (
                                <label
                                  id="u_email-error"
                                  className="error"
                                  style={{
                                    color: "#e74c3c",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    marginTop: "-15px",
                                  }}
                                >
                                  Enter Pickup Location
                                </label>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <strong
                              style={{
                                marginTop: "10px",
                                marginBottom: "-8px",
                              }}
                            >
                              To
                            </strong>
                            <div className="field-holder border">
                              <PlacesAutocomplete
                                value={drop}
                                onChange={onChangedrop}
                                onSelect={handleDrop}
                                ref={dropAutocompleteRef}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <span className="fas fa-map-marker-alt"></span>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Drop Location",
                                        className: "location-search-input",
                                      })}
                                      readOnly={dropValue}
                                    />
                                    {dropValue && (
                                      <span
                                        className="fas fa-times-circle"
                                        style={{
                                          marginRight: "20px",
                                          fontSize: "17px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleClearDropSelection}
                                      ></span>
                                    )}
                                    <div className="autocomplete-dropdown-container">
                                      {/* {loading && <div>Loading...</div>} */}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                          : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                        return (
                                          <div
                                            className="input-suggestion"
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                //className,
                                                style,
                                              }
                                            )}
                                          >
                                            <i class="material-icons ">
                                              location_on
                                            </i>{" "}
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>

                              {err && drop.length <= 0 ? (
                                <label
                                  id="u_email-error"
                                  className="error"
                                  style={{
                                    color: "#e74c3c",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    marginTop: "-13px",
                                  }}
                                >
                                  Enter Destination Location
                                </label>
                              ) : err &&
                                !vehicleKey &&
                                catDisable &&
                                drop.length <= 0 ? (
                                <label
                                  id="u_email-error"
                                  className="error"
                                  style={{
                                    color: "#e74c3c",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    marginTop: "-13px",
                                  }}
                                >
                                  Enter Destination Location
                                </label>
                              ) : drop && drop == address ? (
                                <label
                                  id="u_email-error"
                                  className="error"
                                  style={{
                                    color: "#e74c3c",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    marginTop: "-13px",
                                  }}
                                >
                                  Please Select Different Destination Location
                                </label>
                              ) : null}
                            </div>
                          </div>

                          <div
                            className="col-md-12 col-sm-12"
                            style={{ marginTop: "10px" }}
                          >
                            <strong style={{ marginBottom: "6px" }}>
                              Category
                            </strong>

                            {/* <div className="pckborder"> */}
                            <div className="flexWrapper">
                              {catdata ? (
                                <>
                                  {/* {catdata.reverse().map((item, i) => { */}
                                  {catdata.map((item, i) => {
                                    return (
                                      <div key={i}>
                                        <div
                                          className={`catborder  ${
                                            vehicleKey == i
                                              ? "selectvehicle"
                                              : ""
                                          }`}
                                          onClick={() => chooseVehicle(item, i)}
                                        >
                                          <div className="fleet-name">
                                            {item.cat_name}
                                          </div>
                                          <img
                                            src={catdata[i].cat_icon_path}
                                            alt={"Oops"}
                                            className="catimg"
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            {(address && drop && err && vehicleKey == null) ||
                            (err && vehicleKey == null) ? (
                              <label
                                id="u_email-error"
                                className="error"
                                style={{
                                  color: "#e74c3c",
                                  fontWeight: "500",
                                  fontSize: "15px",
                                  // marginTop: "-5px",
                                  // marginBottom: "15px",
                                }}
                              >
                                Please Select a Category
                              </label>
                            ) : // :
                            // catDisable=="true" ?(
                            //   <label
                            //     id="u_email-error"
                            //     className="error"
                            //     style={{
                            //       color: "#e74c3c",
                            //       fontWeight: "500",
                            //       fontSize: "15px",
                            //       // marginTop: "-5px",
                            //       // marginBottom: "15px",
                            //     }}
                            //   >
                            //     Please Select Pickup and Drop Location
                            //   </label>
                            // )
                            null}
                          </div>
                          <div className="col-md-12 col-sm-12"   style={{ marginTop:"20px" }} >

{/* <PayTypeSelect setIsPaypal={setIsPaypal}/> */}
</div>

                          <div className="col-md-12 col-sm-12">
                            <p className="ride-terms">
                              I understand and agree with the{" "}
                              <a
                                style={{ cursor: "pointer" }}
                                href="https://www.appicial.com/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Terms
                              </a>{" "}
                              of Service and Cancellation{" "}
                            </p>
                            <label for="book_terms">
                              <input
                                name="book_terms"
                                id="book_terms"
                                checked={check}
                                // value={check}
                                type="checkbox"
                                onChange={() => {setcheck(!check)
                           }
                                }
                              />
                              <input
                                type="hidden"
                                id="trip_distance"
                                name="distance"
                              />
                              {err && check == false ? (
                                <label
                                  id="u_email-error"
                                  className="error"
                                  style={{
                                    color: "#e74c3c",
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    marginTop: "-15px",
                                  }}
                                >
                                  Please accept terms and condition
                                </label>
                              ) : null}
                              {/* <div className="col-md-12 col-sm-12"> */}

                              {/* </div> */}
                            </label>
                            <b className="text formText">
                              {/* Your chauffeur will wait 60 minutes free of charge */}
                              Free waiting: 1h airport (other 15 min)
                            </b>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            {/* {(isPaypal && check !== "" && vehicleKey !== null && address !== "" && drop !== address && drop !== "") ? <button className={"book-btn-2"}><span id="paypal-button"></span></button>
                            :  */}
                            <button className="book-btn">
                              Book Now{" "}
                              <i
                                className="fa fa-arrow-circle-rNclassNameight"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </form>
                      ) : bookingForm == "interCity" ? (
                        <InterCity />
                      ) : bookingForm == "rentCar" ? (
                        <RentCar
                          setRentalPackage={setRentalPackage}
                          setRentalTotalFair={setRentalTotalFair}
                          setRentalBaseFair={setRentalBaseFair}
                          setRentalTax={setRentalTax}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* </div> */}

                <div className="col-md-4 col-sm-12">
                  <div className="booking-summary">
                    <h3>Booking Summary</h3>
                    {bookingForm == "daily" ? (
                      <>
                        {address ? (
                          <ul className="booking-info">
                            {city.length != 0 &&
                            category.length != 0 &&
                            coordinate.lat.length != 0 &&
                            coordinate.lng.length != 0 ? (
                              <div>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Base Fare:
                                  </span>{" "}
                                  <span className="booking-info-value">
                                    {/* €{base} */}
                                    €{(estData && estData?.trip_base_fare) ? estData?.trip_base_fare : "0.00"}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    Fare per km:
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    €{Base_fare_per_km}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Fare per min:
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    €{fare_per_min}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Distance:
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    {dropValue ? dist : "0 km"}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Duration
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    {dropValue ? dura : "0 min"}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Sub Total:
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    {dropValue ? <>€
                                    {/* {jtf} */}
                                    {/* {(  calculateDistance > BaseInclude)?
                                          (Math.ceil(
                                            (
                                              Number(jtf) 
                                            ).toFixed(2)
                                          )) :
                                           (parseInt(Base_price)) 
                                          // setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2));
                                        } */}

                                    {(estData && estData?.trip_pay_amount && estData?.tax_amt) ? Number(estData?.trip_pay_amount - estData?.tax_amt).toFixed(2) : "0.00"}
                                    </> : "€0.00"}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Service Tax:
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    {/* // setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2)); */}

                                    {dropValue ? <>€
                                    
                                    
                                    {/* {calculateDistance > BaseInclude ?(((process.env.REACT_APP_SERVICE_TAX * Number(jtf))/100).toFixed(2)):(((process.env.REACT_APP_SERVICE_TAX * Number(Base_price))/100).toFixed(2)) } */}
                                    {(estData && estData?.tax_amt) ? estData?.tax_amt : "0.00"}
                                    </> : "€0.00"}
                                  </span>
                                </p>
                                <p
                                  name="baseaCatPrice"
                                  id="baseaCatPrice"
                                  className="booking-info-content"
                                >
                                  <span className="booking-info-label">
                                    {" "}
                                    Total Fare:
                                  </span>
                                  <span className="booking-info-value">
                                    {" "}
                                    {dropValue ? (
                                      <>
                                
                                        €
                                        {/* {(  calculateDistance > BaseInclude)?
                                          (Math.ceil(
                                            (
                                              Number(jtf) + Number(servTax)
                                            ).toFixed(2)
                                          )) :  Math.ceil( (Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price)) 
                                          // setServTax(((process.env.REACT_APP_SERVICE_TAX * jtf) / 100).toFixed(2));
                                        } */}
                                        {(estData && estData?.trip_pay_amount) ? estData?.trip_pay_amount : "0"}
                                        .00
                                      </>
                                    ) : (
                                      "€0.00"
                                    )}
                                  </span>
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {
                                                city.length!=0 && category.length!=0 && droptrip?
                                                <div>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Base Price:{Base_price}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Base Fare per km:{Base_fare_per_km}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Fare per min:{fare_per_min}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Job Distance:{dist}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Job Time:{dura}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Job Total Fare:{jtf}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Service Tax:{ST = (((parseInt(Base_price)) + (Base_fare_per_km * parseInt(dist)) + (fare_per_min * parseInt(dura))) * (sr / 100)).toFixed(2)}</p>
                                                        <p name="baseaCatPrice" id="baseaCatPrice">Gross Trip Fare:{GTF = (jtf + Number(ST)).toFixed(2)}</p>
                                                    </div>
                                                    : ""
                                            } */}
                          </ul>
                        ) : (
                          ""
                        )}
                      </>
                    ) : bookingForm == "rentCar" ? (
                      <>
                        {" "}
                        {rentalPackage ? (
                          <ul className="booking-info">
                            <div>
                              <p
                                name="baseaCatPrice"
                                id="baseaCatPrice"
                                className="booking-info-content"
                              >
                                <span className="booking-info-label">
                                  Package:
                                </span>
                                <span className="booking-info-value">
                                  {" "}
                                  {rentalPackage}
                                </span>
                              </p>

                              <p
                                name="baseaCatPrice"
                                id="baseaCatPrice"
                                className="booking-info-content"
                              >
                                <span className="booking-info-label">
                                  Estimated Fare:
                                </span>
                                <span className="booking-info-value">
                                  {" "}
                                  €{rentalTotalFair}
                                </span>
                              </p>

                              <p
                                name="baseaCatPrice"
                                id="baseaCatPrice"
                                className="booking-info-content"
                              >
                                <span className="booking-info-label">
                                  {" "}
                                  Base Fare:
                                </span>{" "}
                                <span className="booking-info-value">
                                  €{rentalBaseFair}
                                </span>
                              </p>

                              <p
                                name="baseaCatPrice"
                                id="baseaCatPrice"
                                className="booking-info-content"
                              >
                                <span className="booking-info-label">
                                  {" "}
                                  Taxes:
                                </span>
                                <span className="booking-info-value">
                                  {" "}
                                  €{rentalTax}
                                </span>
                              </p>

                              <p
                                name="baseaCatPrice"
                                id="baseaCatPrice"
                                className="booking-info-content"
                              >
                                <span className="booking-info-label">
                                  {" "}
                                  Total Fare:
                                </span>
                                <span className="booking-info-value">
                                  €{rentalTotalFair}{" "}
                                </span>
                              </p>
                            </div>
                          </ul>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div>
          {/* <section class="tj-user-bfrm">
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-sm-8 col-xs-12">
                  <div class="tj-tabs">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="active">
                        <a href="javascript:void(0)" data-toggle="tab">
                          {" "}
                          Booking Confirm
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane active" id="confirm_booking">
                      <div class="cb-frm" id="rider-info">
                        {message ? (
                          <div className="alert alert-success">{message}</div>
                        ) : (
                          ""
                        )}

                        <div class="col-md-12 col-sm-12 confrim_booking">
                          <div class="info-field">
                            <label>Full Name</label>
                            <span class="far fa-user"></span>
                            <input
                              type="text"
                              name="username"
                              value={fullname}
                              onChange={(e) => setfullname(e.target.value)}
                              placeholder="Enter Full Name"
                              readOnly
                            />
                            
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 confrim_booking_left">
                          <div class="info-field">
                            <label>Phone</label>
                            <span class="icon-phone icomoon"></span>
                            <input
                              type="tel"
                              name="phone_num"
                              value={phone}
                              onChange={(e) => setphone(e.target.value)}
                              placeholder="Enter Phone Number"
                              readOnly
                            />
                           
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 confrim_booking_right">
                          <div class="info-field">
                            <label>Email</label>
                            <span class="far fa-envelope"></span>
                            <input
                              type="email"
                              name="email_id"
                              value={email}
                              onChange={(e) => setemail(e.target.value)}
                              placeholder="Enter Email id"
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12 confrim_booking">
                          <a
                            href="javascript:void(0)"
                            class="back-btn"
                            onClick={handlehide}
                          >
                            <i
                              class="fa fa-arrow-circle-left"
                              aria-hidden="true"
                            ></i>{" "}
                            Go Back
                          </a>
                          <button
                            class="book-btn"
                            id="ride-bbtn"
                            onClick={handleBooknow}
                          >
                            Book Now{" "}
                            <i
                              class="fa fa-arrow-circle-right"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-12">
                  <div class="booking-summary">
                    {" "}
                    <h3>Booking Summary</h3>
                  </div>
                  {city.length != 0 &&
                  category.length != 0 &&
                  address.length != 0 ? (
                    <div class="booking-summary">
                      <ul class="booking-info">
                        <li>
                          <strong>Journey Type: </strong>
                          <div class="service_type">Normal</div>
                        </li>
                      </ul>
                      <ul class="service-info">
                        <li>
                          <strong>From: </strong>
                          <div class="pick_date info-outer">{address}</div>
                        </li>
                        <li>
                          <strong>To: </strong>
                          <div class="pick_date info-outer">{drop}</div>
                        </li>
                        <li>
                          <strong>Pickup Date: </strong>
                          <div class="pick_date info-outer">{form}</div>
                        </li>
                        <li>
                          <strong>Scheduled Time: </strong>
                          <div class="pick_time info-outer">{ftime}</div>
                        </li>
                      </ul>
                      <div class="fare-box">
                        <strong>Trip Estimation</strong>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Base Price: {Base_price}
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Base Fare per km: {Base_fare_per_km}
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Fare per min: {fare_per_min}
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Job Distance: {dist}
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Job Time: {dura}
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Job Total Fare: {jtf}
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Service Tax:{" "}
                          {
                            (ST = (
                              (parseInt(Base_price) +
                                Base_fare_per_km * calculateDistance +
                                fare_per_min * calculateDirection) *
                              (sr / 100)
                            ).toFixed(2))
                          }
                        </p>
                        <p name="baseaCatPrice" id="baseaCatPrice">
                          Gross Trip Fare:{" "}
                          {(GTF = (Number(jtf) + Number(ST)).toFixed(2))}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </section> */}
        </div>
      )}

      <section className="tj-cal-to-action">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon1.png" alt="" />
                <div className="cta-text">
                  <strong>Best Price Guaranteed</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon2.png" alt="" />
                <div className="cta-text">
                  <strong>24/7 Customer Care</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="cta-box">
                <img src="images/cta-icon3.png" alt="" />
                <div className="cta-text">
                  <strong>Easy Bookings</strong>
                  <p>
                    A more recently with desktop softy like aldus page maker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* {paypalLoading ?<div className="spinner_container"><div className="spinner"></div></div> : <></>} */}
    </>
  );
};

export default Booknow;
