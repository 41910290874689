import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { RegisterSchema } from "../../schema";


const Register = () => {
  const [msg, setmsg] = useState("");
  const [show, setshow] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [isoCode, setIsoCode] = useState("");
  const [ide, setide] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setcountry] = useState([]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    country: "",
    phone_no: "",
    referral: "",
    acceptedTos: false,
  };

  useEffect(() => {
    countryData();
  }, []);

  const countryData = () => {
    fetch(`${process.env.REACT_APP_URL}/constantapi/getcountries`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setcountry(result.response);
      });
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RegisterSchema,
      onSubmit: (values, action) => {
        Registerhandle();
        setmsg("");
        //setshow(true)
      },
    });

  const FullName = values.first_name + " " + values.last_name;

  const Registerhandle = () => {
    let data = {
      username: values.first_name,
      u_name: FullName,
      u_email: values.email,
      u_phone: values.phone_no,
      u_password: "null",
      c_code: countryCode.replace(/\D/g, ""),
      city_id: process.env.REACT_APP_CITY_ID,
      u_fname: values.first_name,
      u_lname: values.last_name,
      iso_code: isoCode,
      country_id: ide,
      // u_gender: values.gender,
      ref_id: values.referral,
    };

    fetch(`${process.env.REACT_APP_URL}/userapi/phregistration?`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json().then((result) => {
        if (res.status == 200) {
          values.first_name = "";
          values.last_name = "";
          values.email = "";
          values.gender = "";
          values.phone_no = "";
          values.referral = "";
          values.country = "";
          setTnc(false);
          setshow(true);
        } else {
          setmsg(result.message);
          setshow(false);
        }
      });
    });

  };
  const handleDual = (e) => {
    setTnc(e.target.checked);
    handleChange(e);
  };

  const handleCountryCode = (e) => {
    country.map((res, ind) => {
      if (e.target.value == res.id) {
        setIsoCode(res.iso_code);
        setide(res.id);
        setCountryCode(res.code);
      }
    });
    handleChange(e);
  };

  return (
    <>
      <div class="tab-content">
        <div class="tab-pane active" id="login">
          <div class="col-md-6 col-sm-6">
            <div class="login-cta">
              <ul class="cta-list">
                <li>
                  <span class="icon-mail-envelope icomoon"></span>
                  <div class="cta-info">
                    <strong>30 Days Money Back Guarantee</strong>
                    <p>
                      A more recently with desktop softy like aldus pages maker
                      still versions have evolved.
                    </p>
                  </div>
                </li>
                <li>
                  <span class="icon icon-Headset"></span>
                  <div class="cta-info">
                    <strong>24/7 Customer Support</strong>
                    <p>
                      A more recently with desktop softy like aldus pages maker
                      still versions have evolved.
                    </p>
                  </div>
                </li>
                <li>
                  <span class="icon-lock icomoon"></span>
                  <div class="cta-info">
                    <strong>100% Secure Payment</strong>
                    <p>
                      A more recently with desktop softy like aldus pages maker
                      still versions have evolved.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <form class="reg-frm" id="reg_form" onSubmit={handleSubmit}>
              {show ? (
                <div className="alert alert-success">
                  Registered Successfully
                </div>
              ) : (
                ""
              )}
              {msg ? <div className="alert alert-success">{msg}</div> : ""}
              <div class="field-holder">
                <span class="far fa-user"></span>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  maxLength={25}
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.first_name && touched.first_name
                      ? "input-error"
                      : null
                  }
                />
                {errors.first_name && touched.first_name ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.first_name}
                  </p>
                ) : null}
              </div>
              <div class="field-holder">
                <span class="far fa-user"></span>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  maxLength={25}
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.last_name && touched.last_name ? "input-error" : null
                  }
                />
                {errors.last_name && touched.last_name ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.last_name}
                  </p>
                ) : null}
              </div>
              <div class="field-holder">
                <span class="far fa-envelope"></span>

                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.email}
                  </p>
                ) : null}
              </div>
              {/* <div class="field-holder">
                <span class="fas fa-mars" />
                <select
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.gender && touched.gender ? "input-error" : null
                  }
                >
                  <option>Select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
                {errors.gender && touched.gender ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.gender}
                  </p>
                ) : null}
              </div> */}
              {/* <div class="field-holder">
                                <span class="fas fa-lock"></span>
                                <input type="password" name="password" placeholder="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.password && touched.password ? "input-error" : null} />
                                {errors.password && touched.password ?
                                    <p style={{ color: "#e74c3c", fontWeight: "500", fontSize: "15px" }}>{errors.password}</p>
                                    : null}
                            </div> */}
              <div class="field-holder">
                <span class="fas fa-mobile-alt"></span>
                <select
                  name="country"
                  value={values.country}
                  onChange={handleCountryCode}
                  onBlur={handleBlur}
                  className={
                    errors.country && touched.country ? "input-error" : null
                  }
                >
                  <option>Country Code</option>
                  {country.map((it) => {
                    return (
                      <option value={it.id}>
                        {it.name} {it.code}
                      </option>
                    );
                  })}
                </select>
                {errors.country && touched.country ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.country}
                  </p>
                ) : null}
              </div>
              <div class="field-holder">
              <span class="fas icon-phone icomoon"></span>
              
                <span class="fas icon-phone icomoon"></span>
                <input
                  type="number"
                  name="phone_no"
                  placeholder="Phone No"
                  maxLength={12}
                  value={values.phone_no.toString().replace(/\D/g, "")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone_no && touched.phone_no ? "input-error" : null
                  }
                />
                {errors.phone_no && touched.phone_no ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.phone_no}
                  </p>
                ) : null}
              </div>
              <div class="field-holder" style={{display: "none"}}>
                <span class="fas fa-portrait"></span>
                <input
                  type="text"
                  placeholder="Referral Id"
                  name="referral"
                  maxLength={25}
                  value={values.referral}
                  onChange={handleDual}
                  onBlur={handleBlur}
                  className={
                    errors.referral && touched.referral ? "input-error" : null
                  }
                />
                {errors.referral && touched.referral ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.referral}
                  </p>
                ) : null}
              </div>
              <label for="terms">
                <input
                  type="checkbox"
                  checked={tnc}
                  name="acceptedTos"
                  id="terms"
                  value={values.acceptedTos}
                  onChange={handleDual}
                  onBlur={handleBlur}
                  className={
                    errors.acceptedTos && touched.acceptedTos
                      ? "input-error"
                      : null
                  }
                />
                By signing up, you agree to our
                <a
                  href="https://giovoyage.com/impressum"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://giovoyage.com/datenschutz"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                <br />
                {errors.acceptedTos && touched.acceptedTos ? (
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    {errors.acceptedTos}
                  </p>
                ) : null}
              </label>
              <button type="submit" class="reg-btn">
                Signup{" "}
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
              </button>
            </form>
          </div>
        </div>
        <div></div>

      </div>
    </>
  );
};

export default Register;
