import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import data from "../login/record";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import moment from "moment";
import "../profile/booking.css";
import DateMomentUtils from "@date-io/moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  // TimePicker,
} from "@material-ui/pickers";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { toast } from "react-toastify";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import InterCity from "./InterCity";
import RentCar4 from "./RentCar";
import RentCar from "./RentCar";
import ReserveRental from "./ReserveRental";
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  DesktopTimePicker,
  MobileDateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const HiremeBooknow = () => {
  const today = dayjs();
  const futureTime = today.add(15, "minute");
  const [time, settime] = useState(futureTime);

  // const fTime = today.add(15, "minute");
  const fTime = today;

  const [myDate, setMyDate] = useState(fTime);
  const [customGMTtime, setCustomGMTtime] = useState(
    moment(fTime.$d).format("YYYY-MM-DD" + " " + "hh:mm:ss")
  );
  const [disablePast, setDisablePast] = useState(true); 

  useEffect(()=>{

    let setDate = new Date(myDate).getDate()
    let currentDate = new Date(today.$d).getDate()
       if(setDate != currentDate){
        setDisablePast(false)
       }
       else{
        setDisablePast(true)

       }
  },[myDate])

  // const [city, setcity] = useState(data?.city_id||"");
  const [select, setselect] = useState();
  const [city, setcity] = useState(
    data?.city_id || process.env.REACT_APP_CITY_ID
  );

  const [category, setcategory] = useState(data?.category_id || "");
  const [err, seterr] = useState(false);
  const [drop, setdrop] = useState("");
  const [fullname, setfullname] = useState(sessionStorage.getItem("name"));
  const [phone, setphone] = useState(sessionStorage.getItem("phone"));
  const [email, setemail] = useState(sessionStorage.getItem("email"));
  const [book, setbook] = useState(false);
  const [show, setshow] = useState(true);
  const [message, setmessage] = useState("");
  const [catdata, setcatdata] = useState([]);
  const [base, setbase] = useState("");
  const [farekm, setfarekm] = useState("");
  const [faremin, setfaremin] = useState("");
  const [citydrop, setcitydrop] = useState([]);
  const [cate, setcate] = useState("");
  const [droplatlng, setdroplatlng] = useState("");
  const [pickuptrip, setpickuptrip] = useState(false);
  const [droptrip, setdroptrip] = useState(false);
  const [distance, setDistance] = useState("0.01");
  const [duration, setDuration] = useState("0.00");
  const [check, setcheck] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [calculateDistance, setCalculateDistance] = useState("");
  const [calculateDirection, setCalculateDirection] = useState("");
  const [serviceTax, setServiceTax] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [dropValue, setDropValue] = useState("");
  const [filterCateByCity, setFilterCateByCity] = useState("");
  const autocompleteRef = useRef(null);
  const dropAutocompleteRef = useRef(null);
  const [bookingForm, setBookingForm] = useState("daily");

  const [rentalPackage, setRentalPackage] = useState();
  const [rentalTotalFair, setRentalTotalFair] = useState();
  const [rentalBaseFair, setRentalBaseFair] = useState();
  const [rentalTax, setRentalTax] = useState();

  const [vehicleKey, setvehicleKey] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [categoryId, setCategoryId] = useState();
  const [packName, setPackName] = useState();
  const [catDisable, setCatDisable] = useState("false");
  const [category_Id, setCategory_Id] = useState("false");

  const [servTax, setServTax] = useState(null);

  const [BaseInclude, setbaseInclude] = useState(0);

  const [estimatedFare, setEstimatedFare] = useState("0.00");

  const defaultTime = dayjs().add(15, "minutes"); // Current time + 15 minutes

  const [myTime, setMyTime] = useState(defaultTime);

  const handleTime = (newTime) => {
    setMyTime(newTime);
  };

  // useEffect(() => {
  //   setDropValue("");
  //   setaddress("");
  //   setcategory("")
  // }, [bookingForm]);

  const selectedTime = time.$d
    ? moment(time.$d).seconds(0).milliseconds(0)
    : moment(time).seconds(0).milliseconds(0);
  const currentTime = moment().seconds(0).milliseconds(0);

  const navigate = useNavigate();

  const [address, setaddress] = useState("");
  const [coordinate, setcoordinate] = useState({
    lat: "",
    lng: "",
  });
  const [coord, setcoord] = useState({
    lat: "",
    lng: "",
  });

  const handleTimeChange = (newTime) => {
    const currentTime = moment();
    const selectedTime = moment(newTime);
    if (selectedTime.isAfter(currentTime)) {
      settime(newTime);
    } else {
      toast.error("Please select a future time");
    }
  };

  const handleDateAndTime = (e) => {
    setCustomGMTtime(moment(e.$d).format("YYYY-MM-DD"));
  
    setMyDate(e.$d);

    setselect(e.$d);
    settime(e.$d);
  };

  useEffect(() => {
    if (!city) {
      setcategory("");
      setAddressValue("");
      setaddress("");
      setDropValue("");
      setdrop("");
      // setcatdata([]);
    }
  }, [city]);

  const carouselCatData = localStorage.getItem("carouselCatData");

  useEffect(() => {
    if (carouselCatData) {
      const data = JSON.parse(carouselCatData);
      setbaseInclude(data.cat_base_includes);

      setcity(data.city_id);
      setbase(data.cat_base_price);
      setfarekm(data.cat_fare_per_km);
      setfaremin(data.cat_fare_per_min);

      // setFilterCatecontactApivehiclaByCity(data.city_id);
      setcategory(data.category_id);
      localStorage.removeItem("carouselCatData");
    }
  }, [carouselCatData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        autocompleteRef?.current &&
        !autocompleteRef?.current?.event?.target?.value &&
        !addressValue
      ) {
        setaddress("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [address, addressValue]);

  useEffect(() => {
    const handleDropOutsideClick = (event) => {
      if (
        dropAutocompleteRef?.current &&
        !dropAutocompleteRef?.current?.event?.target?.value &&
        !dropValue
      ) {
        setdrop("");
      }
    };

    document.addEventListener("mousedown", handleDropOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleDropOutsideClick);
    };
  }, [drop, dropValue]);

  const handleClearSelection = () => {
    setAddressValue("");
    setaddress("");
    setdrop("");
    setDropValue("");
  };

  const onChangeAddress = (e) => {
    setaddress(e);
    setAddressValue("");
  };

  const onChangedrop = (e) => {
    setdrop(e);
    setDropValue("");
  };

  const handleClearDropSelection = () => {
    // setaddress("")
    // setAddressValue("");
    setDropValue("");

    setdrop("");
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    setPickupAddress(result[0].formatted_address);
    const l1 = await getLatLng(result[0]);
    setcoordinate(l1);
    setAddressValue(result[0].formatted_address);
    setaddress(value);
  };

  const handleDrop = async (value) => {
    const result = await geocodeByAddress(value);
    setdroplatlng(result[0].formatted_address);
    const l2 = await getLatLng(result[0]);
    setcoord(l2);
    setDropValue(result[0].formatted_address);
    setdrop(value);
    // contactApi()

    // if (address && drop  ){
    //   setCatDisable(false)
    //   return
    //  }else{
    //   setCatDisable(true)
    //  }
  };

  function handleshow() {
    setshow(false);
  }
  function handlehide() {
    setshow(true);
    setmessage(false);
  }

  useEffect(() => {
    if (filterCateByCity) {
      handleCat();
    }
  }, [filterCateByCity]);

  const handleCat = async () => {
    let data = {
      city_id: process.env.REACT_APP_CITY_ID,
    };
    await fetch(
      `${process.env.REACT_APP_URL}/categoryapi/getcategoriesv1`,
      // `https://app.giovoyage.com/webservices/1.1.2/index.php/categoryapi/getcategoriesv1`,

      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcatdata(data.response);
      }).catch((err)=>{
        console.error("Error:",err);
      });
  };

  useEffect(() => {
    catdata?.map((res, ind) => {
      if (selectedVehicle == res.cat_name) {
        setbaseInclude(res.cat_base_includes);

        // setcate(res.cat_name);
        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
      }
    });
    // setbase(catdata.)
  }, [catdata, vehicleKey]);

  // useEffect(() => {
  //   citiesapi();
  // }, []);

  const citiesapi = () => {
    fetch(`${process.env.REACT_APP_URL}/cityapi/getcities?api_key=${apikey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcitydrop(data.response);
      });
  };

  useEffect(() => {
    changelatlng();
    if (coord.lat.length == "" && coord.lng.length == "") {
      setCalculateDistance(0);
      setCalculateDirection(0);
      setDistance(0);
      setDuration(0);
    }
  }, [address, drop]);

  useEffect(() => {
    contactApi();
  }, [drop, address, selectedVehicle]);

  const contactApi = async () => {
    if (coord?.lat.length != 0 && coord?.lng.length != 0) {
      await fetch(
        // `https://rentals.stitchmyapp.com/ver5.1/webservices/push/distanceMatrix.php?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`,
        `${process.env.REACT_APP_DISTANCE_MATRIX_URL}?orlat=${coordinate.lat}&orlng=${coordinate.lng}&delat=${coord.lat}&delng=${coord.lng}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          // dist = setDistance(result?.routes[0]?.legs[0]?.distance?.text);
          // dura = setDuration(result?.routes[0]?.legs[0]?.duration?.text);
          if (addressValue && dropValue) {
            setDistance(result?.routes[0]?.legs[0]?.distance?.text);
            setDuration(result?.routes[0]?.legs[0]?.duration?.text);
          }
          dist = distance;
          dura = duration;
          if (addressValue && dropValue) {
            setCalculateDistance(
              result?.routes[0]?.legs[0]?.distance?.value / 1000
            );
            setCalculateDirection(
              result?.routes[0]?.legs[0]?.duration?.value / 60
            );
          }
          Base_price = base;
          Base_fare_per_km = farekm;
          fare_per_min = faremin;
        }).catch((err)=>{
          console.error("Error:",err);
        });
    } else {
      console.log("message");
    }
  };

  let dist = distance;
  let dura = duration;
  let Base_price = base;
  let Base_fare_per_km = farekm;
  let fare_per_min = faremin;
  let sr = serviceTax;
  let ST = 0;
  let GTF = 0;
  let jtf = (
    parseInt(Base_price) +
    (Base_fare_per_km * (calculateDistance - BaseInclude) +
      fare_per_min * calculateDirection)
  ).toFixed(2);
  // setServTax(((19*jtf)/100).toFixed(2));
  // useEffect(()=>{

  //   // if(vehicleKey){

  //   //   setEstimatedFare(
  //   //     ( Number(jtf) + Number(servTax)).toFixed(2)
  //   //     )
  //   //   }
  //   // setEstimatedFare(
  //   //   ((Number(jtf) + Number(servTax)).toFixed(2)!="NaN" )? (Number(jtf) + Number(servTax)).toFixed(2) :"0.00"
  //   //     )

  // },[jtf,vehicleKey,Base_price,Base_fare_per_km])

  const changelatlng = () => {
    if (addressValue != pickupAddress) {
      setcoordinate({
        lat: "",
        lng: "",
      });
    }
    if (dropValue != droplatlng) {
      setcoord({
        lat: "",
        lng: "",
      });
    }
    if (coordinate.lat.length != 0 && coordinate.lng.length != 0) {
      setpickuptrip(true);
      setdroptrip(false);
    }
    if (
      coordinate.lat.length != 0 &&
      coordinate.lng.length != 0 &&
      coord.lat.length != 0 &&
      coord.lng.length != 0
    ) {
      setdroptrip(true);
      setpickuptrip(false);
    }
  };

  const selectitem = (e) => {
    setcategory(e);
    catdata.map((res, ind) => {
      if (e == res.category_id) {
        setcate(res.cat_name);
        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
      }
    });
  };
  useEffect(() => {
    const selectCity = () => {
      let e = city;
      setcity(e);
      setFilterCateByCity(e);
      setServiceTax(process.env.REACT_APP_SERVICE_TAX);
      // citydrop.map((res) => {
      //   setServiceTax(res.city_tax);
      // });
    };
    selectCity();
  }, [city]);

  //   useEffect(() => {
  //     setEstimatedFare(
  //   ((Number(jtf) + Number(servTax)).toFixed(2)!="NaN" )? (Number(jtf) + Number(servTax)).toFixed(2) :"0.00"
  //     )
  // }, [jtf]);

  const userid = sessionStorage.getItem("userid");

  // const form = moment(select).format("YYYY-MM-DD");
  // // const ftime = moment(time).format("hh:mm A");
  // const time_date = form + " " + ftime;
  const apikey = sessionStorage.getItem("key");

  // var d = new Date(time_date);
  // var utc_offset = d.getTimezoneOffset();
  // d.setMinutes(d.getMinutes() + utc_offset);
  // const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "HH:mm");

  // var d = new Date(customGMTtime);
  // var utc_offset = d.getTimezoneOffset();
  // d.setMinutes(d.getMinutes() + utc_offset);
  // const GMTtime = moment(d).format("YYYY-MM-DD" + " " + "hh:mm:ss");

  const BookingForm = (e) => {
    e.preventDefault();
    if (Validation()) {
      // Create an empty array
      const data = {addressValue:addressValue,dropValue:dropValue,selectedVehicle:selectedVehicle};

     
      // Convert the empty array to a JSON string
      const bookingdata = JSON.stringify(data);

      // Set the JSON string in session storage under a specific key
      sessionStorage.setItem("bookingdetails", bookingdata);

      window.top.location.href = "https://booking.giovoyage.com/login";
      //handleBooknow();
      // handleshow();
    }
  };

  const handleBooknow = (e) => {
    let username = sessionStorage.getItem("phone");
    if (username === "" || username === null) {
      navigate("/login");
    }
    // e.preventDefault();
    if (BookingValidation()) {
      let data = {
        trip_date: customGMTtime,
        cat_name: selectedVehicle,
        trip_from_loc: address,
        trip_to_loc: drop,
        city_id: city,
        user_id: userid,
        category_id: category_Id,
        trip_currency: "€",
        trip_base_fare: jtf,
        trip_scheduled_pick_lat: coordinate.lat,
        trip_scheduled_pick_lng: coordinate.lng,
        trip_scheduled_drop_lat: coord.lat,
        trip_scheduled_drop_lng: coord.lng,
        trip_distance: calculateDistance,
        trip_dunit: "km",
        trip_type: "normal",
        trip_total_time: calculateDirection,
        trip_pay_amount: (Number(jtf) + Number(servTax)).toFixed(2),
        seats: "1",
        trip_status: "request",
        is_share: "0",
        is_delivery: "0",
        tax_amt: servTax,
      };

      fetch(`${process.env.REACT_APP_URL}/tripapi/save?api_key=${apikey}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((result) => {
          if (res.status == 200) {
            setmessage("Your request has been saved successfully,");
            setselect(new Date());
            settime(time);
            setcity("");
            setcategory("");
            setaddress("");
            setdrop("");
            seterr(false);
            toast.success("Your request has been saved successfully,");
            localStorage.setItem("tabValue", "bookinghistory");
            navigate("/profile");
          } else {
            setmessage(result.message);
          }
        });
      });
    }
  };

  const Validation = () => {
    let result = true;

    if (
      city.length == 0 ||
      category.length == 0 ||
      address.length == 0 ||
      check == "" ||
      drop.length == 0 ||
      !selectedTime.isSameOrAfter(currentTime)
    ) {
      result = false;
      seterr(true);
    }
    return result;
  };

  const BookingValidation = () => {
    let result = true;
    if (fullname?.length == 0 || email?.length == 0 || phone?.length == 0) {
      result = false;
      setbook(true);
      setmessage(false);
    }
    return result;
  };

  const [errorWithoutCity, setErrorWithoutCity] = useState(false);
  const handleWithoutCityClick = () => {
    if (!city) {
      setErrorWithoutCity(true);
    }
  };

  useEffect(() => {
    if (address && drop) {
      setCatDisable("false");
      return;
    }
  }, [address, drop]);
  const chooseVehicle = (item, i) => {
    if (address == "" || drop == "") {
      // contactApi();

      setCatDisable("true");
      return;
    } else {
      setCatDisable("false");
    }
    contactApi();

    setvehicleKey(i);
    setSelectedVehicle(item.cat_name);
    setCategoryId(item.category_id);
    setCategory_Id(item.category_id);
    setPackName(item.name);
    setcategory(i);
    catdata.map((res, ind) => {
      if (i == res.category_id) {
        setcate(res.cat_name);
        setbase(res.cat_base_price);
        setfarekm(res.cat_fare_per_km);
        setfaremin(res.cat_fare_per_min);
        // setServTax(((19 / 100) * GTF).toFixed(2));
      }
    });
  };

  const resetDaily = () => {
    setAddressValue("");
    setdrop("");
    setDropValue("");
    setaddress("");
    setSelectedVehicle("");
    setvehicleKey(-1);
    setcategory("");
    setCatDisable("true");
    setcheck("");
  };

  useEffect(() => {
    // let sTax = (
    //   (parseInt(Base_price) +
    //     Base_fare_per_km * calculateDistance +
    //     fare_per_min * calculateDirection) *
    //   (sr / 100)
    // ).toFixed(2);
    let total = (Number(base) + Number(jtf)).toFixed(2);

    setServTax(((19 * jtf) / 100).toFixed(2));
  }, [
    ,
    category_Id,
    address,
    drop,
    vehicleKey,
    selectedVehicle,
    GTF,
    jtf,
    ST,
    city,
  ]);

  const homeRentalForm = () => {
    window.top.location.href = "https://booking.giovoyage.com/login";

    /*
     let username = sessionStorage.getItem("phone");
     if (username === "" || username === null) {
       navigate("/login");
      }
     else{
      navigate("/Booknow");
     }
    */
  };

  return (
    <>
      {/* <!--Booking Form Section Start-->	 */}

      {show ? (
        <div>
          <section className="tj-booking-frm" style={{ padding: "0" }}>
            <div className="container" style={{ padding: "0", margin: "0" }}>
              <div
                className="row"
                style={{
                  display: "flex",
                  margin: "0px",
                }}
              >
                <div className="col-md-4 col-sm-12" style={{ padding: "8px" }}>
                  <div className="tj-tabs">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="active">
                        <a
                          style={{ padding: "8px 30px 8px 30px" }}
                          className="tab"
                          href="#point"
                          data-toggle="tab"
                          onClick={() => {
                            resetDaily();
                            setBookingForm("daily");
                            setRentalPackage(null);
                            setRentalTotalFair(null);
                            setRentalBaseFair(null);
                            setRentalTax(null);
                          }}
                        >
                          Transfer
                        </a>
                      </li>
                      <li className="">
                        <a
                          style={{ padding: "8px 30px 8px 30px" }}
                          href=""
                          data-toggle="tab"
                          onClick={() => {
                            homeRentalForm();
                            setBookingForm("rentCar");
                            setRentalPackage(null);
                            setRentalTotalFair(null);
                            setRentalBaseFair(null);
                            setRentalTax(null);
                          }}
                        >
                          By hour
                        </a>
                      </li>
                      {/* <li className="">
                        <a
                          href="#point"
                          data-toggle="tab"
                          onClick={() => setBookingForm("interCity")}
                        >
                          Inter-city
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane active" id="point">
                      {/* --------------------------------------------------------- */}

                      <div className="tab-pane active" id="point">
                        {bookingForm == "daily" ? (
                          <form
                            style={{ padding: " 42px 0", paddingBottom: "0" }}
                            onSubmit={BookingForm}
                            className="booking-frm"
                            id="User-Form"
                          >
                            <div className="col-md-12 col-sm-12">
                              <strong
                                style={{ marginBottom: 0, marginTop: "-25px" }}
                              >
                                Date
                              </strong>
                              <div
                                className="field-holder"
                                style={{ justifyContent: "flex-start", fontSize:"14px !important"}}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  style={{ fontSize: "14px" }}
                                >
                                  <DesktopDatePicker
                                    className="customDatePicker"
                                    //                                   style={{width:"170px !important",
                                    //                                     height: "330px"}}
                                    value={myDate}
                                    disablePast
                                    ampm
                                    onChange={(e) => handleDateAndTime(e)}
                                    slotProps={{
                                      textField: {
                                        error: false,
                                        inputProps: { readOnly: false },
                                        size: "large",
                                      },
                                    }}
                                    format="DD/MM/YYYY"

                                    // viewRenderers={{
                                    //   hours: renderTimeViewClock,
                                    //   minutes: renderTimeViewClock,
                                    //   seconds: renderTimeViewClock,
                                    // }}
                                  />
                                  <TimePicker
                                    disablePast={disablePast} 
                                    className="customTimePicker"
                                    value={myTime}
                                    onChange={(e) => handleTime(e)}
                                    ampm={false}
                                    // defaultValue={dayjs('2022-04-17T15:30')}
                                  />
                                  {/* <DesktopTimePicker
                                       className="customTimePicker"

                                    //      style={{width:"170px !important",
                                    //     height: "330px"}}
                                    // value={myDate}
                                    disablePast
                                    ampm
                                    onChange={(e) => handleDateAndTime(e)}
                                    slotProps={{
                                      textField: {
                                        error: false,
                                        inputProps: { readOnly: false },
                                        size: "large",
                                      },
                                    }}
                                    // format="DD/MM/YYYY hh:mm A"
                                    // viewRenderers={{
                                    //   hours: renderTimeViewClock,
                                    //   minutes: renderTimeViewClock,
                                    //   seconds: renderTimeViewClock,
                                    // }}
                                  /> */}
                                  {/* <DateTimePicker
                                    //  value={time}
                                    value={myDate}
                                    disablePast
                                    ampm
                                    onChange={(e) => handleDateAndTime(e)}
                                    slotProps={{
                                      textField: {
                                        error: false,
                                        inputProps: { readOnly: false },
                                        size: "large",
                                      },
                                    }}
                                    format="DD/MM/YYYY hh:mm A"
                                  /> */}
                                </LocalizationProvider>
                              </div>
                              {err &&
                              !selectedTime.isSameOrAfter(currentTime) ? (
                                <label
                                  id="u_email-error"
                                  className="error"
                                  style={{
                                    color: "#e74c3c",
                                    fontWeight: "500",
                                    fontSize: "11px",
                                    marginTop: "-10px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Please select a future time
                                </label>
                              ) : null}
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <strong
                                style={{
                                  marginTop: "7px",
                                  marginBottom: "-15px",
                                }}
                              >
                                From
                                {err && address.length <= 0 ? (
                                  <label
                                    id="u_email-error"
                                    className="error"
                                    style={{
                                      color: "#e74c3c",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      marginBottom: "-15px",
                                      float: "right",
                                    }}
                                  >
                                    *Enter Pickup Location
                                  </label>
                                ) : null}
                              </strong>
                              <div
                                className={`field-holder border padding-input`}
                              >
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={onChangeAddress}
                                  onSelect={handleSelect}
                                  ref={autocompleteRef}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div>
                                      <span className="fas fa-map-marker-alt"></span>
                                      <input
                                        {...getInputProps({
                                          placeholder: "Pickup Location",
                                          className: "location-search-input",
                                        })}
                                        readOnly={addressValue}
                                      />
                                      {addressValue && (
                                        <span
                                          className="fas fa-times-circle"
                                          style={{
                                            marginRight: "20px",
                                            fontSize: "17px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleClearSelection}
                                        ></span>
                                      )}

                                      <div className="autocomplete-dropdown-container">
                                        {/* {loading && <div>Loading...</div>} */}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              className="input-suggestion"
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  // className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <i class="material-icons ">
                                                location_on
                                              </i>{" "}
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                                {/*                  
                           {err && address.length <= 0 ? (
                             <label
                               id="u_email-error"
                               className="error"
                               style={{
                                 color: "#e74c3c",
                                 fontWeight: "500",
                                 fontSize: "11px",
                                 marginTop: "-11px",
                               }}
                             >
                               Enter Pickup Location
                             </label>
                           ) : null} */}
                              </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <strong
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "-15px",
                                }}
                              >
                                To
                                {err && drop.length <= 0 ? (
                                  <label
                                    id="u_email-error"
                                    className="error"
                                    style={{
                                      color: "#e74c3c",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      marginBottom: "-13px",
                                      float: "right",
                                    }}
                                  >
                                    *Enter Destination Location
                                  </label>
                                ) : err &&
                                  !vehicleKey &&
                                  catDisable &&
                                  drop.length <= 0 ? (
                                  <label
                                    id="u_email-error"
                                    className="error"
                                    style={{
                                      color: "#e74c3c",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      marginBottom: "-13px",
                                      float: "right",
                                    }}
                                  >
                                    *Enter Destination Location
                                  </label>
                                ) : null}
                              </strong>
                              <div className="field-holder border">
                                <PlacesAutocomplete
                                  value={drop}
                                  onChange={onChangedrop}
                                  onSelect={handleDrop}
                                  ref={dropAutocompleteRef}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div>
                                      <span className="fas fa-map-marker-alt"></span>
                                      <input
                                        {...getInputProps({
                                          placeholder: "Drop Location",
                                          className: "location-search-input",
                                        })}
                                        readOnly={dropValue}
                                      />
                                      {dropValue && (
                                        <span
                                          className="fas fa-times-circle"
                                          style={{
                                            marginRight: "20px",
                                            fontSize: "17px",
                                            cursor: "pointer",
                                          }}
                                          onClick={handleClearDropSelection}
                                        ></span>
                                      )}
                                      <div className="autocomplete-dropdown-container">
                                        {/* {loading && <div>Loading...</div>} */}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          // inline style for demonstration purpose
                                          const style = suggestion.active
                                            ? {
                                                backgroundColor: "#fafafa",
                                                cursor: "pointer",
                                              }
                                            : {
                                                backgroundColor: "#ffffff",
                                                cursor: "pointer",
                                              };
                                          return (
                                            <div
                                              className="input-suggestion"
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  //className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <i class="material-icons ">
                                                location_on
                                              </i>{" "}
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>

                                {/* {err && drop.length <= 0 ? (
                             <label
                               id="u_email-error"
                               className="error"
                               style={{
                                 color: "#e74c3c",
                                 fontWeight: "500",
                                 fontSize: "11px",
                                 marginTop: "-13px",
                               }}
                             >
                               Enter Destination Location
                             </label>
                           ) : err &&
                             !vehicleKey &&
                             catDisable &&
                             drop.length <= 0 ? (
                             <label
                               id="u_email-error"
                               className="error"
                               style={{
                                 color: "#e74c3c",
                                 fontWeight: "500",
                                 fontSize: "11px",
                                 marginTop: "-13px",
                               }}
                             >
                               Enter Destination Location
                             </label>
                           ) : null} */}
                              </div>
                            </div>

                            <div
                              className="col-md-12 col-sm-12"
                              style={{ marginTop: "-1px" }}
                            >
                              <strong style={{ marginBottom: "2px" }}>
                                Category
                                {(address &&
                                  drop &&
                                  err &&
                                  vehicleKey == null) ||
                                (err && vehicleKey == null) ? (
                                  <label
                                    id="u_email-error"
                                    className="error"
                                    style={{
                                      color: "#e74c3c",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      marginBottom: "-15px",
                                      float: "right",
                                      // marginTop: "-5px",
                                      // marginBottom: "15px",
                                    }}
                                  >
                                    *Please Select a Category
                                  </label>
                                ) : null}
                              </strong>

                              {/* <div className="pckborder"> */}
                              <div className="flexWrapper">
                                {catdata ? (
                                  <>
                                    {/* {catdata.reverse().map((item, i) => { */}
                                    {catdata.map((item, i) => {
                                      return (
                                        <div key={i}>
                                          <div
                                            style={{ width: "98px" }}
                                            className={`catborder-reserve      ${
                                              vehicleKey == i
                                                ? "selectvehicle"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              chooseVehicle(item, i)
                                            }
                                          >
                                            <div className="fleet-name">
                                              {item.cat_name}
                                            </div>
                                            <img
                                              src={catdata[i].cat_icon_path}
                                              alt={"Oops"}
                                              className="catimg-reserve"
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* {(address && drop && err && vehicleKey == null) ||
                         (err && vehicleKey == null) ? (
                           <label
                             id="u_email-error"
                             className="error"
                             style={{
                               color: "#e74c3c",
                               fontWeight: "500",
                               fontSize: "11px",
                               // marginTop: "-5px",
                               // marginBottom: "15px",
                             }}
                           >
                             Please Select a Category
                           </label>
                         ) : 
                         null} */}
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                  marginTop: "10px",
                                }}
                              >
                                <input
                                  style={{ marginTop: "4px" }}
                                  name="book_terms"
                                  id="book_terms"
                                  checked={check}
                                  type="checkbox"
                                  onChange={(e) => setcheck(!check)}
                                />
                                <p
                                  className="book_terms-reserve"
                                  style={{ fontSize: "13px" }}
                                >
                                  I understand and agree with the{" "}
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      color: "#d71b24",
                                    }}
                                    href="https://giovoyage.com/impressum"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Terms
                                  </a>{" "}
                                  of Service and Cancellation.{" "}
                                </p>
                              </div>
                              <label for="book_terms">
                                <input
                                  type="hidden"
                                  id="trip_distance"
                                  name="distance"
                                />
                                {err && check == false ? (
                                  <label
                                    id="u_email-error"
                                    className="error"
                                    style={{
                                      color: "#e74c3c",
                                      fontWeight: "500",
                                      fontSize: "11px",
                                      marginTop: "-19px",
                                      marginBottom: "5px",
                                      float: "right",
                                    }}
                                  >
                                    *Please accept terms and condition
                                  </label>
                                ) : null}
                              </label>
                              {/* <b className="reserve_text" >
                                 Your chauffeur will wait 25 minutes free of charge
                               </b>{" "} */}
                               <b className="text formText mb-1">Free waiting: 1h airport (other 15 min)</b>

                              <div
                                class="field-outer"
                                style={{
                                  background: "beige",
                                  height: "30px",
                                  textAlign: "center",
                                  // marginTop: "-10px",
                                  padding: "6px",
                                  color: "black",
                                }}
                              >
                                {/* Book Now{" "} */}
                                <strong style={{ color: "black" }}>
                                  {" "}
                                  Estimated Fare: €{/* {estimatedFare} */}
                                  {addressValue&&dropValue ? (Math.ceil(
                                    (Number(jtf) + Number(servTax)).toFixed(
                                      2
                                    ) != "NaN"
                                      ? 
                                      ((  calculateDistance > BaseInclude)?
                                      
                                      (Number(jtf) + Number(servTax)).toFixed(
                                          2
                                        ):((Number(process.env.REACT_APP_SERVICE_TAX * Base_price) / 100)+ parseInt(Base_price)))
                                      : "0"
                                  )) : 0}
                                  {/* {addressValue&&dropValue ? (Math.ceil(
                                    (Number(jtf) + Number(servTax)).toFixed(
                                      2
                                    ) != "NaN"
                                      ? (Number(jtf) + Number(servTax)).toFixed(
                                          2
                                        )
                                      : "0"
                                  )) : 0} */}
                                  .00
                                </strong>
                              </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                              <button
                                className="book-btn"
                                style={{ width: "100%", marginTop: "7px" }}
                              >
                                Book Now{" "}
                                <i
                                  className="fa fa-arrow-circle-rNclassNameight"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </form>
                        ) : bookingForm == "interCity" ? (
                          <InterCity />
                        ) : (
                          // : bookingForm == "rentCar" ? (
                          // <ReserveRental
                          // setRentalPackage={setRentalPackage}
                          // setRentalTotalFair={setRentalTotalFair}
                          // setRentalBaseFair={setRentalBaseFair}
                          // setRentalTax={setRentalTax}
                          // rentalTotalFair={rentalTotalFair}
                          // />
                          // )
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default HiremeBooknow;